<template> <!-- wildfire morntoring -->
<div>  
  <!-- example1 -->
  <CRow>
    <CCol :xs="4">
      <CWidgetStatsF color="primary" title="Title" value="$1.999,50">
        <template #icon>
          <CIcon icon="cil-settings" size="xl"/>
        </template>
      </CWidgetStatsF>
    </CCol>
    <CCol :xs="4">
      <CWidgetStatsF color="info" title="Title" value="$1.999,50">
        <template #icon>
          <CIcon icon="cil-settings" size="xl"/>
        </template>
      </CWidgetStatsF>
    </CCol>
    <CCol :xs="4">
      <CWidgetStatsF color="warning" title="Title" value="$1.999,50">
        <template #icon>
          <CIcon icon="cil-settings" size="xl"/>
        </template>
      </CWidgetStatsF>
    </CCol>
  </CRow>
 <!-- example2 -->
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4" v-for="(item, index) in data" v-if="index>=0&&index<=3">
        <div class="card">
          <div class="card-header p-3 pt-2">
            <!-- icon shape box -->
            <div class="icon icon-shape icon-shape-secondary bg-gradient text-center border-radius-xl mt-n4 position-absolute" >
              <button class="btn2" :style="{backgroundColor:item.color}"><i :class="item.icon" ></i></button> 
            </div>
            <div class="text-right pt-1">
              <p class="text-sm mb-0 text-capitalize">{{item.name}}</p>
              <h1 class="text-sm mb-0 text-capitalize">{{item.val}}</h1>
              
            </div>
          </div>
          <div class="p-3"> <!-- p: height  -->
            <!-- text-success -->
            <p class="mb-0"><span style="font-size:12px;color:grey;"><i class="fa fa-plus" ></i> 자세히 보기</span></p> 

          </div>
        </div>
      </div> 
      </div>
  </div>
</div>
</template>

<script>
import Summation from '@/views/dashboard/Summation'

export default {
  name: 'SWFMSummation',
  extends: Summation,
  data () {
    return {
      headerTitles: [
        '전국 산불위험지수 현황'
      ],
      data: [
        {icon: 'fa fa-home', name : '전체', color: 'DodgerBlue',key: 'BG', val: 1 },
        {icon: 'fa fa-check-square', name : '정상', color: 'ForestGreen',key: 'BN', val: 0 },
        {icon: 'fa fa-warning', name : '연기감지', color: 'Gold', key: 'BC', val: 0 },
        {icon: 'fa fa-fire', name : '화재감지', color: 'OrangeRed',key: 'BW', val: 0 },
      ]
    }
  },
  methods: {
    update(count, events) {
      this.data.forEach(elem => elem.val = 0);
      //var pms_total = 0;

      events.forEach(row => {
        var key = 'BN';
        switch (row.sensor_type) {
          case 1:
            key = row.level == 1 ? 'BN' : row.level == 2 ? 'BC' : 'BW';
            break;
          case 28:
            //key = row.level == 1 ? 'PN' : row.level == 2 ? 'PC' : 'PW'; pms_total += 1
            break;
        }
        var item = _.find(this.data, { key: key })
        item.val += 1
      })

      var item = _.find(this.data, { key : 'BG' })
      item.val = count - (_.find(this.data, {key:'BN'}).val + _.find(this.data, {key:'BC'}).val + _.find(this.data, {key:'BW'}).val)
      //item = _.find(this.data, { key : 'PG' })
      //item.val = pms_total - (_.find(this.data, {key:'PN'}).val + _.find(this.data, {key:'PC'}).val + _.find(this.data, {key:'PW'}).val)
    }
  }
}
</script>

<style scoped>
.table {
    width: 100%;
    margin-bottom: 0rem;
    color: #3c4b64;
}

.btn {
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
}

.btn2 {
  border: none;
  color: white;
  padding: 12px 21px;
  font-size: 2rem;
  cursor: pointer;
  border-radius: 10%;
}

/* Darker background on mouse-over */
.btn:hover {
  background-color: RoyalBlue;
}
</style>