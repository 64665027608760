<template>
  <div>
    <CRow>
      <CCol col="12" xl="6">
        <CCard style="height:19.4%; width:102%;">
          <Summation ref="summationView" @click="onClick"/>
        </CCard>
        <CCard style="height:77.5%; width:102%;">
          <Device ref="deviceView" @change="onChangedSite" prefix="srds_"/>
        </CCard>
      </CCol>
      <CCol col="12" xl="6">
        <CCard style="height:100%; width:102%;">
            <GoogleMap :sensor_type="8" :sensor_level="1" ref="googleMap" style="height:60%;padding-top:2.0%;padding-bottom:2.0%;padding-left:2.0%;padding-right:2.0%;" />
            <Event ref="eventView" @update="updateSummation" @click="onClickEvent" prefix="srds_"/>
        </CCard>
      </CCol>
    </CRow>

    <EventDialog ref="eventDialog" @click="onClickEvent"/>
  </div>
</template>

<script>

import GoogleMap from '@/views/srds/GoogleMap'

import Summation from "@/views/srds/Summation";
import Device from "@/views/srds/Device";
import EventDialog from "@/views/srds/EventDialog";
import Event from "@/views/srds/Event";
import utils from "@/services/utils";

export default {
  name: "SRDSBoard",
  components: {
    Summation,
    GoogleMap,
    Device,
    EventDialog,
    Event,
  },
  created: function () {
    this.user = this.$store.state.auth.user_info.user;
    this.sites = this.$store.state.auth.user_info.sites;

    var userInfo = utils.getStorage("userInfo");
  },
  data() {
    return {
      is_admin: this.$store.state.auth.is_admin,
      selected_row: null,
      selected_site_devices: [],
    };
  },
  methods: {
    onClick(item) {
      // console.log('Dashboard::onClick Summation click item:', JSON.stringify(item,null,2))
      if(item.val != 0 &&item.key != 'DN'){
        var eventTable = this.$refs.eventView.getTable();
        this.$refs.eventDialog.show(item, eventTable.data);
      }
    },
    onClickEvent(field, value, row) {
      this.$refs.deviceView.onEvent(field, value, row);
    },
    onChangedSite(siteTable) {
      // console.log('Dashboard::onChangedSite siteTable:', siteTable)
      this.selected_site_devices = siteTable;
      var eventTable = this.$refs.eventView.getTable();
      eventTable.data.forEach((event) => {
        // console.log('Dashboard::onChangedSite setMakerImage(event):', event)
        this.$refs.deviceView.setMarkerImage(event);
      });
    },
    updateSummation(data) {
      var where = {
        and: [
          {
            status: 0,
            service_type: 'srds',
          },
        ],
      };
      if (this.is_admin === false) {
        where.and.push({
          site_guid: {
            inq: this.user.site_guids,
          }
        })
      }
      this.$store
        .dispatch("rest/count", { model: "devices", where: where })
        .then((res) => {
          this.$refs.summationView.update(res.count, data);
        })
        .then(()=>{
          var eventTable = this.$refs.eventView.getTable();
          eventTable.data.forEach((event) => {
            this.$refs.deviceView.setMarkerImage(event);
          });
        });
    },
  },
};
</script>