<template>
  <div>
    <CRow>
      <CCol col="12" xl="1">
        <!--<Weather  prefix="swfm_"/>-->
      </CCol>
      <CCol col="12" xl="10">      
        <CCard>
          <Summation ref="summationView" />
        </CCard>
        <CCard>
            <Device ref="deviceView" @change="onChangedSite" prefix="swfm_"/>
            <Event @update="updateSummation" @click="onClickEvent" prefix="swfm_"/>
        </CCard>
      </CCol>
      <CCol col="12" xl="1">
        <!--<Weather  prefix="swfm_"/>-->
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Dashboard from "@/views/srds/Dashboard";
import Summation from "@/views/swfm/Summation";
import Device from "@/views/swfm/Device";
import Event from "@/views/swfm/Event";

export default {
  name: "SWFMDashboard",
  extends: Dashboard,
  components: {
    Summation,
    Device,
    Event
  },
  methods: {
    onChangedSite(siteTable) {
      // console.log('Dashboard::onChangedSite siteTable:', siteTable)
      this.selected_site_devices = siteTable;
      // var eventTable = this.$refs.eventView.getTable();
      // eventTable.data.forEach((event) => {
      //   // console.log('Dashboard::onChangedSite setMakerImage(event):', event)
      //   this.$refs.deviceView.setMarkerImage(event);
      // });
    },
    updateSummation(data) {
      var where = {
        and: [
          {
            status: 0,
          },
        ],
      };
      if (this.is_admin === false) {
        where.and.push({
          site_guid: {
            inq: this.user.site_guids,
          }
        })
      }
      this.$store
        .dispatch("rest/count", { model: "devices", where: where })
        .then((res) => {
          this.$refs.summationView.update(res.count, data);
        });
    },
  },
};
</script>
