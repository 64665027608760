import { render, staticRenderFns } from "./Event.vue?vue&type=template&id=df7b0f62&scoped=true&"
import script from "./Event.vue?vue&type=script&lang=js&"
export * from "./Event.vue?vue&type=script&lang=js&"
import style0 from "./Event.vue?vue&type=style&index=0&id=df7b0f62&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df7b0f62",
  null
  
)

export default component.exports