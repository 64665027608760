<template>
  <CCol col="12" xl="12">
    <table class="tg">
      <thead>
        <tr>
          <th class="tg-baqh">날씨</th>
          <th class="tg-baqh">기온(℃)</th>
          <th class="tg-baqh">습도(%)</th>
          <th class="tg-baqh">풍향(풍)</th>
          <th class="tg-baqh">풍속(m/s)</th>
          <th class="tg-baqh">강우(mm/h)</th>
        </tr>
      </thead>
      <tbody>
        <tr>    
          <td class="tg-baqh"><i class="fa fa-sun-o fa-3x" style="color:orange;"></i>{{siteWeather.pty}}</td>
          <td class="tg-baqh"><i class="fa fa-thermometer-half fa-3x" style="color:red;"></i>{{siteWeather.temp}}</td>
          <td class="tg-baqh"><i class="fa fa-tint fa-3x" style="color:royalblue;padding-top: 2px;"></i>{{siteWeather.humi}}</td>
          <td class="tg-baqh">{{siteWeather.wind_d}}</td>
          <td class="tg-baqh">{{siteWeather.wind_s}}</td>
          <td class="tg-baqh">{{siteWeather.rain}}</td>
        </tr>
      </tbody>
    </table>
  </CCol>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import utils from '@/services/utils';

export default {
  name: 'Weather',
  components: {
  },
  props: {
    prefix: {
      type: String,
      default: ''
    }
  },
  created: function() {
    this.userInfo = this.$store.state.auth.user_info;
    this.user = this.userInfo.user;
    this.sites = this.userInfo.sites

    this.active_site_index = _.get(this.user, "active_site_index", 0);
    //this.active_site_name = this.user.active_site_name;

    this.getWeatherData(this.active_site_index, this.user.active_site_name);
  },
  data () {
    return {
      active_site_index: 0,
      siteWeather: {}
    }
  },
  methods: {
    getWeatherData(index) {
      var self = this;
      var default_site_guid = _.get(this.user, "default_site_guid");
      var data = {
        from: moment(new Date().setMinutes(0, 0, 0)).subtract(1,'hour'),
        to: moment(new Date().setMinutes(0, 0, 0)).add(1,'hour'),
        site_guid: _.isEmpty(this.user.active_site_guids[index]) ? default_site_guid : this.user.active_site_guids[index]        
      }
      return loopback
        .method('weather', 'getWeatherStat', data)
        .then(res => {
          if(res.data.length == 0){
            var data = {
                fcstTime: moment(new Date().setMinutes(0, 0, 0)).format('YYYYMMDDHH00').toString(),
                temp: '23.7',
                humi: '41',
                wind_s: '3.1',
                wind_d: '132',
                winds_ew: '-2.2',
                winds_ns: '2.1',
                rain: '0',
                pty: 'sun'
            }
            //console.log("load default data: ", data);
            data.wind_d = utils.getWindDirectionName(data.wind_d)
            self.siteWeather = data;
          }
          else{
            res.data[0].wind_d = utils.getWindDirectionName(Number(res.data[0].wind_d))
            self.siteWeather = res.data[0];
          }

          //console.log("load default data: ", res);
          //self.siteWeather.site_guid = data.site_guid;
          //console.log(data.site_guid)
          //console.log('Device::getWeather_stats: ', JSON.stringify(self.siteWeather,null,2))
          return Promise.resolve(self.siteWeather)
        })
    } // getDeviceList
  }
}
</script>

<style scoped>
.tg .tg-baqh {
    text-align: center;
    vertical-align: inherit;
}
</style>
