<template>
  <div>
  </div>
</template>

<script>
import Event from '@/views/device/Event'

export default {
  name: 'SWFMEvent',
  extends: Event
}
</script>