<template>
  <div>
<!--    <CRow>
      <CCol col="12" xl="6">
        <h4 style="padding-left:5.0%;padding-top:3.0%;font-weight:bold;">기상정보</h4>
      </CCol>
      <CCol col="12" xl="6">
        <div style="text-align: right;padding-left:2.0%;padding-right:1.0%;">
          <CDropdown color="primary" :toggler-text="site_name" class="m-2" ref="dropdown">
          <li v-for="(site, index) in siteTables" :key="site.title">
            <CDropdownItem :class="{active:index==active_site_index}" data-toggle="tab" :id="'#site' + index" :href="'#site_tab' + index" @click="onClickTab(index)" role="tab">{{site.title}}<a v-if="site.device_count!=0">({{site.device_count}}대)</a></CDropdownItem>        
          </li>
          </CDropdown>
        </div>
      </CCol>
    </CRow>
    <Weather ref="weather" style="height:50%;padding-bottom:1.0%;padding-left:2.0%;padding-right:2.0%;"  />

    <GoogleMap ref="googleMap" style="height:50%;padding-bottom:2.0%;padding-left:2.0%;padding-right:2.0%;" />

    <div class="tab-content">
      <div class="tab-pane" v-for="(site, index) in siteTables" :id="'site_tab' + index"  :key="site.title" :class='{active:index==active_site_index}' role="tabpanel">
        <div class="card-body">
          <v-table :id="'dataTable'+index" :columns="site.columns" :data="site.data" :options="site.options" @on-click-row="onClickRow"></v-table>
        </div>
      </div>
    </div>-->
    <CRow>
    <CCol col="12" xl="7">
      <GoogleMap ref="googleMap" style="height:100%;padding-top:1.0%;padding-bottom:1.0%;padding-right:1.0%" />
    </CCol>

    <CCol col="12" xl="5">
      <CRow>
        <CCol col="12" xl="6">
          <h4 style="padding-left:4.0%;padding-top:5.5%;font-weight:bold;">기상정보</h4>
        </CCol>
        <CCol col="12" xl="6">
          <div style="text-align: right;padding-left:2.0%;padding-right:1.0%;">
            <CDropdown color="primary" :toggler-text="site_name" class="m-2" ref="dropdown">
              <li v-for="(site, index) in siteTables" :key="site.title">
                <CDropdownItem :class="{active:index==active_site_index}" data-toggle="tab" :id="'#site' + index" :href="'#site_tab' + index" @click="onClickTab(index)" role="tab">{{site.title}}<a v-if="site.device_count!=0">({{site.device_count}}대)</a></CDropdownItem>        
              </li>
            </CDropdown>
          </div>
        </CCol>
      </CRow>
      <Weather ref="weather" style="padding-bottom:1.0%;padding-left:1.5%;padding-right:2.0%;"  />
      <div class="tab-content">
        <div class="tab-pane" v-for="(site, index) in siteTables" :id="'site_tab' + index"  :key="site.title" :class='{active:index==active_site_index}' role="tabpanel">
          <div class="card-body">
            <v-table :id="'dataTable'+index" :columns="site.columns" :data="site.data" :options="site.options" @on-click-row="onClickRow"></v-table>
          </div>
        </div>
      </div>
    </CCol>
  </CRow>    
  </div>
</template>

<script>

import Device from '@/views/dashboard/Device';
import GoogleMap from '@/views/swfm/GoogleMap'
import Weather from '@/views/swfm/Weather';
import utils from '@/services/utils';

export default {
  name: 'SWFMDevice',
  extends: Device,
  components: {
    GoogleMap,
    Weather
  },
  props: {
    prefix: {
      type: String,
      default: 'swfm_'
    }
  },
  data () {
    return {
      site_name: '설치 사이트'
    }
  },
  methods:{
    onClickTab(index) {
      var active_site_index = _.get(this.user, "active_site_index", -1);
      if (active_site_index != index) {
        this.user.active_site_index = index;
        this.user.active_site_name = this.siteTables[index].title;
        utils.setStorage("userInfo", this.userInfo);
      }
      this.site_name = this.user.active_site_name;
      this.$refs.weather.getWeatherData(index);
      return this.getDeviceList(index)
        .then((siteTable) => {
          return Promise.resolve(siteTable)
        })
    },
  }
}
</script>