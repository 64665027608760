<template>
  <div>
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item navbar-custom" v-for="(site, index) in siteTables" v-if='site.data && index<=site_tab_limit'>
          <a tab class="nav-link" :class="{active:index==active_site_index}" data-toggle="tab" :id="'#site' + index" :href="'#site_tab' + index" @click="onClickTab(index)" role="tab">{{site.title}}</a>
        </li>
        <div style="text-align: right; margin-top: -5px;" v-show="siteTables.length>site_tab_limit">
          <CDropdown color="primary" :toggler-text="site_name" class="m-2" ref="dropdown">
            <li v-for="(site, index) in siteTables" :key="site.title" v-if='site.data && index>site_tab_limit'>
              <CDropdownItem :class="{active:index==active_site_index}" data-toggle="tab" :id="'#site' + index" :href="'#site_tab' + index" @click="onClickTab(index)" role="tab">{{site.title}}</CDropdownItem>        
            </li>
          </CDropdown>
        </div>        
      </ul>

      <div class="tab-content" >
        <div class="tab-pane" v-for="(site, index) in siteTables" :id="'site_tab' + index" :class='{active:index==active_site_index}' role="tabpanel" >
          <div class="card-body">
            <v-table :id="'dataTable'+index" :columns="site.columns" :data="site.data" :options="site.options" @on-click-row="onClickRow"></v-table>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

import loopback from '@/services/loopback';
import moment from 'moment';
import EventBus from '@/services/EventBus'
import utils from '@/services/utils';
import { IMAGES } from "@/constants/constant"


import {
  DEVICE_TABLE_OPTIOINS, SRDS_DEVICE_TABLE_COLUMS,  LEAF_TYPES
} from "@/constants/deviceSettings";

export default {
  name: 'Device',
  components: {
  },
  props: {
    prefix: {
      type: String,
      default: 'srds_'
    }
  },
  created: function() {
    this.userInfo = this.$store.state.auth.user_info;
    this.user = this.userInfo.user;
    this.sites = this.userInfo.sites
    
    this.active_site_index = _.get(this.user, "active_site_index", 0)
    this.getDashboard()
    EventBus.$on('reload', this.getDashboard)
  },
  mounted() {
    $('#dataTable1').on('all.bs.table', function(e, row, $element) {
      console.log('all.bs.table e:', e)
    });
  },
  beforeDestroy(){
    EventBus.$off('reload', this.getDashboard)
  },
  data () {
    return {
      active_site_index: 0,
      site_name: '설치 사이트',
      site_tab_limit: this.$is_mobile ? 4 : 16,
      siteTables: [],
      dashboard: {},
      sensor_type: utils.getSensorTypes()
    }
  },
  methods: {
    makeTable(site_guids) {
      if (site_guids.length === this.siteTables.length) {
        return;
      }
      this.siteTables = [];
      
      DEVICE_TABLE_OPTIOINS.sortName="level";
      DEVICE_TABLE_OPTIOINS.sortOrder="desc";

      for (var i = 0; i < site_guids.length; i++) {
        var table = {
          options: DEVICE_TABLE_OPTIOINS,
          columns: SRDS_DEVICE_TABLE_COLUMS,
          title: '',
          data: []
        }
        // _.find(table.columns, { field: 'site' }).formatter = this.siteFormatter;
        _.find(table.columns, { field: 'name' }).formatter = this.nameFormatter;
        _.find(table.columns, { field: 'sensor' }).formatter = this.sensorFormatter;
        _.find(table.columns, { field: 'level' }).formatter = this.imageFormatter2;
        _.find(table.columns, { field: 'battery' }).formatter = this.batteryStatusFormatter;
        _.find(table.columns, { field: 'sensor_time' }).formatter = utils.dateFormatter;
        this.siteTables.push(table)
      }
    },
    getDeviceUrl(row) {
      return '#/{0}device/{1}'.format(this.prefix, row.guid);
    },
    siteFormatter(value, row, index) {
      return "<a href='#/{0}sites/{1}'>{2}</a>".format(this.prefix, row.site_guid, value);
    },
    nameFormatter(value, row, index) {
      return "<a href='#/{0}device/{1}'>{2}</a>".format(this.prefix, row.guid, row.name);
    },
    imageFormatter(value, row, sensor_data) {
      if (_.isUndefined(value) || value.length < 1)
        return this.buildsensorFormatter(sensor_data, row);

      var label = '';
      sensor_data.data.forEach(function(v) {
        if (!_.get(row, "empty", false)) {
          if (v.id === 0) v.id = 1;
          switch(v.type){
            case 9:
              label = v.value == 1 ? "<span style = 'color: red; font-weight: bold; background: rgba(0,0,0,0.7);'> 충격감지 </span>"  : '';
              break;
          }
        }
      })
      var images = []
      var device_url = this.getDeviceUrl(row);
      for (var i = 0; i < value.length; i++) {
        var el = value[i]
        if (_.isUndefined(el.storage)) {
          continue
        }
        var url = loopback.defaults.baseURL + '/' + el.storage + 's/' + el.device_guid + '/download/' + el.file_name;
        images.push({
          url: url,
          label: label,
          created_at: el.created_at
        })
      }
      images = _.sortBy(images, ['created_at']).reverse();
      var target_id = 'img_' + row.guid
      var result = '<div id="' + target_id + '" class="carousel slide" data-ride="carousel" data-interval="5000">' +
        '<ol class="carousel-indicators hidden">';      
      //for (var i = 0; i < images.length; i++) {
        //var elem = '<li data-target="#{0}" data-slide-to="{1}" {2}></li>'
        //result += elem.format(target_id, i, i == 0 ? 'class="active"' : '')
      //}
      result += '</ol>'
      //result += '<div class="carousel-inner" >'
      //for (var i = 0; i < images.length; i++) {
        //var item = '<div class="carousel-item {0}">'
        var img = '<a href="{0}"><img src="{1}" alt="" style="width: 150px; height: 80px;"></a>'
        var caption = '<div class="carousel-caption"><p>{0}</p></div></div>'
        //result += item.format(i == 0 ? 'active' : '')
        result += img.format(device_url, images[0].url)
        result += caption.format(images[0].label)
      //}
      //result += '</div>'

      //result += '<a class="carousel-control-prev" href="#' + target_id + '" role="button" data-slide="prev">'
      //result += '<span class="carousel-control-prev-icon" aria-hidden="true"></span>'
      //result += '<span class="sr-only">Previous</span>'
      //result += '</a>'
      //result += '<a class="carousel-control-next" href="#' + target_id + '" role="button" data-slide="next">'
      //result += '<span class="carousel-control-next-icon" aria-hidden="true"></span>'
      //result += '<span class="sr-only">Next</span>'
      //result += '</a>'
      //result += '</div>'

      result += '<style scoped>'
      result += '.carousel-indicators {'
      result += '  bottom: -25px;'
      result += '  z-index: 10;'
      result += '}'
      result += '.carousel-inner {'
      result += '  width: 100%;'
      result += '  max-height: 256px !important;'
      result += '  background-color: silver !important;'
      result += '}'
      result += '.carousel-caption {'
      result += '  bottom: -10px;'
      result += '  z-index: 10;'
      result += '  padding-top: 20px;'
      result += '  padding-bottom: 0px;'
      result += '}'
      result += '</style>'
      // console.log('[Device Carousel html] : ', result)
      return result;
    },
    imageFormatter2(value, row, index) {
      var imgsrc;
      var status_text = "";

      if (value == 1){ 
        imgsrc = IMAGES.DN.name;
        status_text = IMAGES.DN.status;
      }
      else if (value == 2){
        //console.log("level2");                
        imgsrc = IMAGES.DC.name;
        status_text = IMAGES.DC.status;
      }
      else if (value == 3){
        //console.log("level3");
        imgsrc = IMAGES.DW.name;
        status_text = IMAGES.DW.status;
      }
      if(imgsrc == undefined)
          imgsrc = "img/humidity-lack.png";

      return '<div class="containerBox"><img class="img-responsive" src=' + imgsrc + ' height=' + IMAGES.size.height + 'width=' + IMAGES.size.width + ' / ><div class="threshold-text-box"><h6 class="threshold-dataNumber">' + status_text + '</h6></div></div> ';
    },    
    levelFormatter(row) {
      var level = "";
      var result_x = "";
      var result_y = "";
      var thresholds = utils.getThresholds();
      try {
        var level0= _.find(thresholds, {sensor_type : 8, level : 0 }).min; //0
        var level2= _.find(thresholds, {sensor_type : 8, level : 2 }).min; //3
        var level3= _.find(thresholds, {sensor_type : 8, level : 3 }).min; //5
      } catch (err) {
        return;
      }

      row.sensor.data.forEach(function(e){
        if(e.type === 8){
          if(e.id === 1)
            result_x = Math.abs(e.value - row.standard.x).toFixed(2);
          else if(e.id === 2)
            result_y = Math.abs(e.value - row.standard.y).toFixed(2);
        }
      })
      
      level = IMAGES.DN.level;
      
      if (result_x >= level2 && result_x < level3){
        level = IMAGES.DC.level;
      }
      else if (result_x >= level3 ){
        level = IMAGES.DW.level;
      }
      else if (result_y >= level2 && result_y < level3){
        level = IMAGES.DC.level;
      }
      else if (result_y >= level3 ){
        level = IMAGES.DW.level;
      }      
      return level;
    },        
    batteryStatusFormatter(value, row, index) {
      var result = "";
      var status_text = "";
      var imgsrc;
      var thresholds = utils.getThresholds();
      try {
        var level0= _.find(thresholds, {sensor_type : 1, level : 0 }).min;
        var level1= _.find(thresholds, {sensor_type : 1, level : 1 }).min;
        var level2= _.find(thresholds, {sensor_type : 1, level : 2 }).min;
        var level3= _.find(thresholds, {sensor_type : 1, level : 3 }).min;
      } catch(err) {
        return;
      }
      if (_.isUndefined(row.sensor)){
          console.log("isUndefined");
          return;
      }

      row.sensor.data.forEach(function(e){
        if(e.type === 1)
          result = e.value;
      })

      if (result >= level0){ 
        //console.log("level0");
        imgsrc = IMAGES.BG.name;
        status_text = IMAGES.BG.status;
      }
      else if (result >= level1 ){ 
        //console.log("level1");
        imgsrc = IMAGES.BN.name;
        status_text = IMAGES.BN.status;
      }
      else if (result >= level2 ){
        //console.log("level2");                
        imgsrc = IMAGES.BC.name;
        status_text = IMAGES.BC.status;
      }
      else if (result >= level3 ){
        //console.log("level3");
        imgsrc = IMAGES.BW.name;
        status_text = IMAGES.BW.status;        
      }
      else{
        //console.log("교체필요");              
        return "<span style = 'color: red; font-weight: bold;'> 교체필요 </span>";
      }

      return '<div class="containerBox"><img class="img-responsive" src=' + imgsrc + ' height=' + IMAGES.size.height + 'width=' + IMAGES.size.width + ' / ><div class="threshold-text-box"><h6 class="threshold-dataNumber">' + status_text + '</h6></div></div> '

    },
    sensorFormatter(value, row, index) {
      var self = this;
      if (row.service_type === 'srds') {
        return self.imageFormatter(row.images, row, value);
      }
      return self.buildsensorFormatter(value, row);
    },
    buildsensorFormatter(value, row){
      var self = this
      var result = "";
      value.data = _.sortBy(value.data, ['type']);
      value.data.forEach(function(v) {
        if (!_.get(row, "empty", false)) {
          if (v.id === 0) v.id = 1;
          var description = _.find(self.sensor_type, {
            code: v.type,
            id: v.id
          });
          if (!description) return
          
          var desc = "";
          switch(v.type){
            case 1:
              //desc = '배터리:' + v.value + description.unit + ' '; 
              break;
            case 8:
              if(v.id === 1) desc = 'x축:' + v.value + description.unit + ' ';
              else if(v.id === 2) desc = 'y축:' + v.value + description.unit + ' ';
              else if(v.id === 3) desc = 'z축:' + v.value + description.unit + ' ';
              break;
            case 9:
              desc = v.value == 1 ? "<br><span style = 'color: red; font-weight: bold;'> 충격감지 </span>"  : '';
              break;
          }
          result += desc;
        }
        else
          result = "측정값 없음";
      })
      return "<a>" + result + "</a>";
    },
    onClickTab(index) {
      var active_site_index = _.get(this.user, "active_site_index", -1);
      if (active_site_index != index) {
        this.user.active_site_index = index;
        this.user.active_site_name = this.siteTables[index].title;
        utils.setStorage("userInfo", this.userInfo);
      }
  
      if(index > this.site_tab_limit)
        this.site_name = this.user.active_site_name;
  
      return this.getDeviceList(index)
        .then((siteTable) => {
          return Promise.resolve(siteTable)
        .then(()=>{
            if (!this.$parent.$refs.eventView) return null;
            var eventTable = this.$parent.$refs.eventView.getTable();
            eventTable.data.forEach((event) => {
              this.$parent.$refs.deviceView.setMarkerImage(event);
            });
          })
        })
        .catch(err => {
          // ignore
        })
    },
    onEvent(event, value, row) {
      var site_guid = event.site;
      var active_site_index = _.get(this.user, "active_site_index", -1);
      var index = _.indexOf(this.user.active_site_guids, site_guid);
      if (active_site_index !== index) {
        $('[href="#site_tab' + index + '"]').tab('show');
        this.onClickTab(index).then(res => {
          if (res != null) this.setMarkerImage(event)
        })
      } else {
        this.setMarkerImage(event)
      }

    },
    onClickRow(field, value, row) {
      this.$parent.$refs.googleMap.setCenter(field)
    },
    setMarkerImage(event) {
      this.$parent.$refs.googleMap.setMarkerImage(event)
    },
    getDashboard() {
      var self = this;
      var filter = {
        where: {
          user_guid: this.user.guid
        }
      }
      this.$store.dispatch('rest/find', {model:'dashboards',filter:filter})
        .then((res) => {
          self.dashboard = _.first(res);
          self.getDeviceList(self.active_site_index)
        })
    },
    getDeviceList(index, limit, skip) {
      var self = this;
      if (typeof limit === 'undefined') limit = 50
      if (typeof skip === 'undefined') skip = 0

      var default_site_guid = _.get(this.user, "default_site_guid");
      var active_site_index = _.get(this.user, "active_site_index", 0)

      var data = {
        limit: limit,
        skip: skip,
        site_guid: _.isEmpty(this.user.active_site_guids) ? this.user.site_guids : this.user.active_site_guids,
        active_site_index: active_site_index
      }

      // this.makeTable(data.site_guid)
      return loopback
        .method('sites', 'getDeviceList', data)
        .then(res => {
          // console.log('Device::getDeviceList: ', JSON.stringify(res,null,2))
          self.makeTable(_.map(res, 'site_guid'))
          self.user.active_site_guids = []
          res.forEach(function(d, i) {
            var rows = [];
            d.data.forEach(function(e) {
              var data = {
                site: d.Site.name,
                site_guid: d.Site.guid,
                site_index: i,
                center_device_guid: d.Site.center_device_guid,
                latitude: e.latitude,
                longitude: e.longitude,
                name: e.name,
                service_type: e.service_type,
                address: e.address,
                sensor: e.sensor,
                standard: e.standard,
                empty: e.sensor.empty,
                sensor_time: e.sensor.created_at,
                guid: e.guid,
                threshold: e.threshold,
                images: e.images,
                level: self.levelFormatter(e)
              }
              rows.push(data)
            });
            var active_site_index = _.get(self.user, "active_site_index", -1);
            if (active_site_index != -1 && self.active_site_index != active_site_index) {
              if (active_site_index < res.length)
                self.active_site_index = active_site_index;
            } else {
              if (default_site_guid == d.site_guid) {
                self.active_site_index = i;
              }
            }

            if (_.indexOf(self.user.active_site_guids, d.site_guid) === -1) {
              self.user.active_site_guids.push(d.site_guid);
            }
            self.siteTables[i].title = d.Site.name;
            self.siteTables[i].data = rows;
            self.siteTables[i].device_count = rows.length;
          });
          var siteTable = self.siteTables[self.active_site_index]
          self.user.active_site_name = siteTable.title;
          utils.setStorage("userInfo", self.userInfo);
          if (!_.isUndefined(self.$parent.$refs.googleMap))
            self.$parent.$refs.googleMap.update(siteTable);

          // notify to parent : change
          self.$emit('change', siteTable)
          return Promise.resolve(siteTable)
        })
    } // getDeviceList
  }
}
</script>

<style dashdevice>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}

.text-box {
  position: absolute;
  height: 80%;
  color: white;
  text-align: center;
  width: 100%;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: 30px;
}
.dataNumber {
  margin-top: auto;
}
.containerBox {
  position: relative;
  display: inline-block;
}
.img-responsive{ 
  display: block; 
  max-width: 100%; 
  height: auto; 
} 
.threshold-text-box { 
  position: absolute; 
  height: 30%; 
  color: white; 
  text-align: center; 
  width: 100%; 
  margin: auto; 
  top: 0; 
  bottom: 0; 
  right: 0; 
  left: 0; 
  font-size: 30px; 
} 
.threshold-dataNumber { 
  margin-top: auto; 
  font-size: 12px; 
  font-weight: bold;
} 
/* .carousel-indicators {
  position: absolute;
  right: ((100% - $carousel-caption-width) / 2);
  bottom: -25px;
  left: ((100% - $carousel-caption-width) / 2);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 0px;
}
.carousel-inner {
  width: 100%;
  max-height: 256px !important;
  background-color: silver !important;
}
.carousel-caption {
  position: absolute;
  right: ((100% - $carousel-caption-width) / 2);
  bottom: -10px;
  left: ((100% - $carousel-caption-width) / 2);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 0px;
  color: $carousel-caption-color;
  text-align: center;
} */
</style>