<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>기울기 주의/위험 알람 목록</strong>
        <div class="card-header-actions">
          <CLink href="#" class="card-header-action btn-close" v-on:click="onCloseEvent()" :disabled="selected_row == null">
            <i class="fa fa-trash-o" style="font-size:15px"></i> <a>알람종료</a>
          </CLink>
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol col="12" xl="12">
            <v-table id="eventTable"
              :columns="eventTable.columns" 
              :data="eventTable.data" 
              :options="eventTable.options" 
              @on-check="onCheckSite" 
              @on-uncheck="onUncheckSite"
              @on-click-row="onClickRow"
            ></v-table>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <Confirm
        ref="confirmDialog"
        @hide="hideModal"
    />

    <ImageDialog ref="imageDialog"/>

    <Notify ref="notifyDialog"/>

  </div>

</template>

<script>
import loopback from '@/services/loopback';
import Summation from '@/views/srds/Summation'
import { EVENT_TABLE_OPTIOINS, SRDS_EVENT_TABLE_COLUMS } from "@/constants/eventSettings"
import { IMAGES } from "@/constants/constant"
import EventBus from '@/services/EventBus'
import utils from '@/services/utils'
import ImageDialog from '@/views/srds/ImageDialog'
import moment from 'moment';

export default {
  name: 'Event',
  components: {
    ImageDialog
  },
  props: {
    device_guid : {
      type: String,
      default: undefined
    },
    prefix: {
      type: String,
      default: 'srds_'
    },
    event_height: {
      type: Number,
      default: -1
    }
  },
  created: function() {
    this.user = this.$store.state.auth.user_info.user;
    this.sites = this.$store.state.auth.user_info.sites

    var userInfo = utils.getStorage('userInfo')
    this.eventTable.options.clickToSelect = true;
    this.eventTable.options.singleSelect = true;
    _.find(this.eventTable.columns, { field: 'created_at' }).formatter = utils.dateFormatter;
    _.find(this.eventTable.columns, { field: 'is_closed' }).visible = false;
    _.find(this.eventTable.columns, { field: 'device_name' }).formatter = this.nameFormatter;
    _.find(this.eventTable.columns, { field: 'device_address' }).visible = false;
    _.find(this.eventTable.columns, { field: 'level' }).formatter = this.imageFormatter2;
    _.find(this.eventTable.columns, { field: 'site' }).formatter = this.siteFormatter;
    _.find(this.eventTable.columns, { field: 'pre_angle_change' }).visible = false;
    //_.find(this.eventTable.columns, { field: 'pre_angle_change' }).formatter = this.pre_angleStatusFormatter;
    _.find(this.eventTable.columns, { field: 'angle_change' }).formatter = this.sensorValFormatter;
    _.find(this.eventTable.columns, { field: 'sensor_scope' }).formatter = this.sensorScopeFormatter;
    //_.find(this.eventTable.columns, { field: 'battery' }).formatter = this.batteryStatusFormatter;
    this.getEventList()
    EventBus.$on('reload', this.reloadListener);
    EventBus.$on('alarmData', this.onEvent)
    if (this.event_height != -1) {
      this.eventTable.options.height = this.event_height
    }
  },
  beforeDestroy(){
    EventBus.$off('alarmData', this.onEvent)
    EventBus.$off('reload', this.reloadListener)
  },
  data () {
    return {
      selected_row: null,
      screens: [
        {
          deviceImages: [],
        }
      ],   
      eventTable: {
        options: _.cloneDeep(EVENT_TABLE_OPTIOINS),
        columns: _.cloneDeep(SRDS_EVENT_TABLE_COLUMS),
        data: [],
      }
    }
  },
  methods: {
    reloadListener() {
      console.log('Event::reloadListener')
      this.getEventList();
    },
    nameFormatter(value, row, index) {
      return "<a href='#/{0}device/{1}'>{2}</a>".format(this.prefix, row.device_guid, row.device_name);
    },
    getSiteName(site_guid) {
      var site = this.sites.filter(function(e) {
        return e.guid == site_guid;
      })[0];
      return _.get(site, "name", "");
    },
    siteFormatter(value, row, index) {
      return "<a href='#/{0}sites/{1}'>{2}</a>".format(this.prefix, value, this.getSiteName(value));
    },
    closeFormatter(value, row, index) {
      return value == true ? 'Y' : 'N';
    },
    imageFormatter2(value, row, index) {
      var imgsrc;
      Object.keys(IMAGES).some(function(key, i) {
        if ((row.sensor_type % 64) === IMAGES[key].type && row.level === IMAGES[key].level) {
          imgsrc = IMAGES[key].name;
          return true;
        }
      });
      if(imgsrc == undefined)
          imgsrc = "img/humidity-lack.png";

      return '<img src=' + imgsrc + ' height=' + IMAGES.size.height + 'width=' + IMAGES.size.width + ' / > '
    },
    sensorScopeFormatter(value, row, index){ 
      var result = " ";
      result += `<i class="material-icons" style="font-size:25px;">zoom_in</i>`;
      return result 
    },
    sensorTypeFormatter(value, row, index) {
      return utils.getSensorTypeName(row.sensor_type, row.sensor_id, true);
    },
    batteryStatusFormatter(value, row, index) {
      var result = "";
      var thresholds = utils.getThresholds();
      var level0= _.find(thresholds, {sensor_type : 1, level : 0 }).min;
      var level1= _.find(thresholds, {sensor_type : 1, level : 1 }).min;
      var level2= _.find(thresholds, {sensor_type : 1, level : 2 }).min;
      var level3= _.find(thresholds, {sensor_type : 1, level : 3 }).min;

      if (_.isUndefined(value)){
          console.log("isUndefined");
          return;
      }
      result = value;
      
      if (result >= level0){ 
        //console.log("level0");
        return "<span style = 'color: green' > 좋음 </span>";
      }
      else if (result >= level1 ){ 
        //console.log("level1");
        return "<span> 보통 </span>";
      }
      else if (result >= level2 ){
        //console.log("level2");                
        return "<span style = 'color: orange'> 주의 </span>";
      }
      else if (result >= level3 ){
        //console.log("level3");
        return "<span style = 'color: red'> 경고 </span>";
      }
      else{
        //console.log("교체필요");              
        return "<span style = 'color: red; font-weight: bold'> 교체필요 </span>";
      }
    },
   sensorValFormatter(value, row, index){
      var result = "";
      var thresholds = utils.getThresholds();

      var level0= _.find(thresholds, {sensor_type : 8, level : 0 }).min; //0
      var level2= _.find(thresholds, {sensor_type : 8, level : 2 }).min; //3
      var level3= _.find(thresholds, {sensor_type : 8, level : 3 }).min; //5

      var current_value = Object.entries(row.sensor_value).map(ele => { 
          var value = ele[1]
          return value; 
        })

      var diff = Object.entries(row.angle_diff).map(ele => { 
          var value = ele[1]
          return value; 
        })
      var span = " "
      var xyzString = ["x :  ", " y :  ", " z :  "];

      for(var i = 0 ; i < current_value.length; i++){
          
          if(diff[i] > level3 || diff[i] <= (level3*(-1))){ 
          span = "<span style = 'color: red; font-weight:bolder'>"
            
          }else if (diff[i] > level2 || diff[i] <= (level2*(-1))){ 
            span = "<span style = 'color: orange;'>"
          }
          else span = "<span style = 'color: green; '>"
          
          if(i<=1) 
            result += ' • '+ xyzString[i] + " " + current_value[i] +  "º  ( " + span + diff[i] + "</span> ) "
          else
            result += ' • '+ xyzString[i] + " " + current_value[i] +  "º  ( " + diff[i] + ") "
        }
      
      return result;
    },    
    angleStatusFormatter(value, row, index) {
      var result = "";

      if (_.isUndefined(value)){
          console.log("isUndefined");
          return;
      }
        result += 'x: ' + value.x_value + '(º) ';
        result += 'y: ' + value.y_value + '(º) ';
        result += 'z: ' + value.z_value + '(º)';

      return result;
    },
    pre_angleStatusFormatter(value, row, index) {
      var result = "";      

      if (_.isUndefined(value)){
          console.log("isUndefined");
          return;
      }
      //console.log(result);
        result += 'x: ' + value.x + '(º) ';
        result += 'y: ' + value.y + '(º) ';
        result += 'z: ' + value.z + '(º)';

      return result;
    },
    onCheckSite(row, $element) {
      this.getDeviceImages(row);
      this.selected_row = row;
    },
    onUncheckSite(row, $element) {
      this.selected_row = null;
    },
    hideModal(params) {
      if (_.isUndefined(params)) {
        return; // cancel command
      }
      switch (params.name) {
        case 'Confirm':
          if (params.which === 'delete' && params.data === 'confirm') {
            this.removeEvent(this.selected_row)
          }
          break;
        default:
          break
      }
    },
    getDeviceImages(row) {
      var self = this
      this.screens.forEach(view => {
        view.deviceImages = [];
      })
      var selected_device_guid = row.device_guid;
      /*****************************************************/
      //var dates = this.$parent.getDates();
      /*****************************************************/
      //var from = moment(row.created_at).format('YYYY-MM-DD HH:mm:ss');
      //var to = moment(row.created_at).format('YYYY-MM-DD HH:mm:ss');

      var filter = {
        where: {
          and: [{
              device_guid: selected_device_guid
            },
          //   {
          //     created_at: {
          //       "gte": from
          //     }
          //   },
          //   {
          //     created_at: {
          //       "lt": to
          //     }
          //   }
          ]
        },
        order: 'created_at desc',
        limit: 1,
      }

      this.$store.dispatch('rest/find', {model:'photos',filter:filter})
        .then(function(response) {
          var pushImage = function(info) {
            var path = '{0}/{1}s/{2}/download/{3}'.format(loopback.defaults.baseURL, info.storage, selected_device_guid, info.file_name)
            var index = 0;
            self.screens[index].deviceImages.push({
              url: path,
              created_at: moment(info.created_at).format('YYYY년 MM월 DD일 HH시 mm분 ss초')
            })
          }
          for (var i = 0; i < response.length; i++) {
            var info = response[i]
            if (_.isUndefined(info.storage)) continue;
            // if (_.isUndefined(info.file.crop)) continue;
            pushImage(info);
          }
        })
    },    
    onCloseEvent() {
      // console.log('Event::onCloseEvent selected_row:', JSON.stringify(this.selected_row,null,2))
      if (this.selected_row === null) return;

      let message = '이벤트 : ' + this.selected_row.device_name + ' 알림을 종료 하시겠습니까?'
      this.$refs.confirmDialog.show(message, '이벤트 종료', undefined, 'delete');
    },   
    onClickRow(field, value, row) {
      if(row === 'sensor_scope'){
        if(!_.isUndefined(this.screens[0].deviceImages[0])){
          var img =JSON.parse(JSON.stringify(this.screens[0].deviceImages[0],null,2));
          this.$refs.imageDialog.show(field.device_name+" " + img.created_at+" 사진", img);
        }
      }
      this.$emit('click', field, value, row)
    },
    getTable() {
      return this.eventTable;
    },
    onEvent(message) {
      if (_.isUndefined(message.datas))
        return;

      var eventTable = $('#eventTable');
      message.datas.forEach(function(element) {
        var data = element.data;
        switch (element.subtype) {
          case 'add': {
            if (!_.isUndefined(data.Device)) {
              data["device_name"] = data.Device.name;
              data["device_address"] = data.Device.address;
              data["site_guid"] = data.Device.site_guid;
            }
            eventTable.bootstrapTable('prepend', data);
            break;
          }
          case 'delete': {
            eventTable.bootstrapTable('remove', {
              field: 'guid',
              values: [data.guid]
            });
            break;
          }
          case 'update': {
            eventTable.bootstrapTable('updateByUniqueId', {
              id: data.guid,
              row: data
            });
            break;
          }
        }
      })
      self.$emit('update', self.eventTable.data)
    },
    removeEvent(event) {
      loopback
        .upsertWithWhere('/events', {guid: event.guid}, {is_closed: true})
        .then((res) => {
          console.log('Event::removeEvent OK: ', res)
          $('#eventTable').bootstrapTable('remove', {
            field: 'guid',
            values: [event.guid]
          });
        })
        .catch((err) => {
          console.log('removeEvent error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
    },
    getEventList() {
      var self = this
      var data = {
        is_closed: 0,
        device_guid: this.device_guid
      }
      // console.log('Event::getEventList data:', JSON.stringify(data))
      this.$store.dispatch('rest/method', {model:'events', method:'getAlarmList', data:data})
        .then((res) => {
          self.eventTable.data = [];
          res.forEach(function(row, index) {
            if(row.service_type === "srds"){
              self.eventTable.data.push({
                created_at: row.created_at,
                description: row.description,
                service_type: row.service_type,
                sensor_type: row.sensor_type,
                sensor_id: row.sensor_id,
                is_closed: row.is_closed,
                level: row.level,
                device_guid: row.device_guid,
                device_name: _.get(row, "device.name"),
                device_address: _.get(row, "device.address"),
                site: _.get(row, "device.site_guid"),
                guid: row.guid,
                sensor_value: row.sensor_value,
                pre_angle_change: row.standard,
                angle_diff: row.angle_diff,
                sensor_scope:"",
                battery: row.battery,
              });
            }
          });
          self.$emit('update', self.eventTable.data)
        })
        .catch((err) => {
          console.log('queryEvent event: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })

    }
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  /* padding: 0.01rem; */
  padding: 0.50rem;
}
</style>