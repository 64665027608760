<template>
  <CRow>
    <CCol col="12" xl="12" v-if="$is_mobile">
      <table id="statTable" class="table table-striped table-bordered table-hover" data-toggle="table">
        <thead>
          <tr>
            <th colspan="4" class="text-center" v-for="(name, index) in headerTitles" v-if="index==0">{{name}}</th>
          </tr>
          <tr>
            <th class="text-center" v-for="(name, index) in subTitles" v-if="index>=0&&index<=3">{{name}}</th>
          </tr>
        </thead>
        <tbody>
          <td class="text-center" v-for="(item, index) in data" v-if="index>=0&&index<=3">
            <div class="containerBox">
              <img class="img-responsive" :src="findImage(item.key)" width="30pt" height="30pt"/>
              <div class="text-box"><h5 class="dataNumber">{{item.val}}</h5></div>
            </div>
          </td>
        </tbody>
      </table>
    </CCol>

    <CCol col="12" xl="12" v-else>
      <table id="statTable" class="table table-striped table-bordered table-hover" data-toggle="table">
        <thead>
          <tr>
            <th colspan="4" class="text-center" v-for="name in headerTitles">{{name}}</th>
          </tr>
          <tr>
            <th class="text-center" v-for="name in subTitles">{{name}}</th>
          </tr>
        </thead>
        <tbody>
          <td class="text-center" v-for="item in data"  @click="onClick(item)">
            <div class="containerBox">
              <img class="img-responsive" :src="findImage(item.key)" width="30pt" height="30pt"/>
              <div class="text-box"><h5 class="dataNumber">{{item.val}}</h5></div>
            </div>
          </td>
        </tbody>
      </table>
    </CCol>
  </CRow>
</template>

<script>
import Summation from '@/views/dashboard/Summation'
import { SRDS_IMAGES as IMAGES } from "@/constants/constant"

export default {
  name: 'SRDSSummation',
  extends: Summation,
  data () {
    return {
      headerTitles: [
        '기울기 위험등급'
      ],
      subTitles: [
        '전체', '정상', '주의', '위험'
      ],
      data: [
        { key: 'DG', val: 1 }, { key: 'DN', val: 0 }, { key: 'DC', val: 0 }, { key: 'DW', val: 0 }
      ]
    }
  },
  methods: {
    update(count, events) {
      this.data.forEach(elem => elem.val = 0);

      events.forEach(row => {
        var key = 'BG';
        var item = _.find(this.data, { key: key })
        switch (row.sensor_type) {
          case 1:
            key = row.level == 1 ? 'BN' : row.level == 2 ? 'BC' : 'BW';
            item = _.find(this.data, { key: key });
            item.val += 1;
            break;
          case 8:
            key = row.level == 2 ? 'DC' : 'DW';
            item = _.find(this.data, { key: key });
            item.val += 1;
            break;
        }

      })

      var item = _.find(this.data, { key : 'DN' })
      item.val = count - (_.find(this.data, {key:'DC'}).val + _.find(this.data, {key:'DW'}).val)

      item = _.find(this.data, { key : 'DG' })
      item.val = (_.find(this.data, { key : 'DN' }).val + _.find(this.data, {key:'DC'}).val + _.find(this.data, {key:'DW'}).val)
    }
  }
}
</script>