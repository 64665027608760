import { render, staticRenderFns } from "./Summation.vue?vue&type=template&id=28a1ceec&scoped=true&"
import script from "./Summation.vue?vue&type=script&lang=js&"
export * from "./Summation.vue?vue&type=script&lang=js&"
import style0 from "./Summation.vue?vue&type=style&index=0&id=28a1ceec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28a1ceec",
  null
  
)

export default component.exports